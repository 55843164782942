import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZpxTableComponent } from '../zpx-table/zpx-table.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { PassholderEventsTableDataSource } from './passholder-events-table.datasource';
import {
  ZpxApiPassholderParams,
  ZpxFrontendPageParams
} from '@src/app/models/zpx-api.model';
import { ZonarUITableModule } from '@zonar-ui/table';
import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@src/app/app.service';

@Component({
  selector: 'app-passholder-events-table',
  standalone: true,
  providers: [PassholderEventsTableDataSource],
  imports: [CommonModule, ZpxTableComponent, ZonarUITableModule],
  templateUrl: './passholder-events-table.component.html',
  styleUrls: ['./passholder-events-table.component.scss']
})
export class PassholderEventsTableComponent implements OnInit, OnDestroy {
  constructor(
    private passholderEventsDataSource: PassholderEventsTableDataSource,
    private getEnvService: GetEnvironmentService,
    private dialog: MatDialog,
    private appService: AppService
  ) {}

  private onDestroy$ = new Subject<void>();
  columns$ = new BehaviorSubject([]);
  dataSource = this.passholderEventsDataSource;
  offset = 0;
  currentPage: number = 1;

  pageSize =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  totalResults: number = 0;

  setPagingParams(
    feParams: ZpxFrontendPageParams,
    beParams: ZpxApiPassholderParams,
    total: number
  ): ZpxApiPassholderParams {
    if (feParams.page === 1) {
      this.offset = 0;
    } else {
      if (feParams.page > this.currentPage) {
        const lastPage = Math.floor(total / feParams.per_page);

        // + 1 to lastPage to account for non-array page numbering, e.g. a lastPage of 10 would be 11 for frontend
        const isLastPage = lastPage + 1 === feParams.page;

        if (isLastPage) {
          this.offset = total - feParams.per_page;
        } else {
          // prevent setting an offset that is greater than data array length
          // will never actually occur in the UI, but put this safeguard in for edgecase testing purposes
          const nextOffset = this.offset + feParams.per_page;
          this.offset = nextOffset < total ? nextOffset : this.offset;
        }
      } else {
        // prevent setting offset to be a negative number
        // will never actually occur in the UI, but put this safeguard in for edgecase testing purposes
        const nextOffset = this.offset - feParams.per_page;
        this.offset = nextOffset >= 0 ? nextOffset : 0;
      }
    }

    this.currentPage = feParams.page;

    const newPagingParams = {
      ...beParams,
      offset: this.offset,
      limit: feParams.per_page
    };

    return newPagingParams;
  }

  ngOnInit(): void {
    this.dataSource.paginationParams$
      .pipe(
        filter((fePageParams) => Boolean(fePageParams.page)),
        withLatestFrom(this.dataSource.total$),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        const standardColumns = this.dataSource.getStandardColumns();
        this.columns$.next(standardColumns);
        this.dataSource.getPassholderEventsForTable();
      });
  }
  ngOnDestroy(): void {
    this.dataSource.onTableDestroy();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.columns$.complete();
  }
}
