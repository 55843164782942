import { Component, inject, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';
import {
  Passholder,
  PassholderForTable,
  PassholderPatchBody
} from '@src/app/models/zpx-api.model';
import { PassholderFilterBarComponent } from '../passholder-filter-bar/passholder-filter-bar.component';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { DataDogService } from '@src/app/services/data-dog/data-dog.service';
import { AppService } from '@src/app/app.service';
import { SelectedCompanyService } from '@services/selected-company/selected-company.service';
import { PassholderEventsTableComponent } from '@components/passholder-events-table/passholder-events-table.component';
import { map } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { ZuiDatePickerModule } from '@zonar-ui/date-picker';

@Component({
  selector: 'app-cardholder-modal',
  standalone: true,

  imports: [
    CommonModule,
    MatDialogModule,
    MatTabsModule,
    FlexLayoutModule,
    PassholderFilterBarComponent,
    PassholderEventsTableComponent,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    FormsModule,
    ZuiDatePickerModule
  ],
  templateUrl: './passholder-modal.component.html',
  styleUrls: ['./passholder-modal.component.scss']
})
export class PassholderModalComponent {
  constructor(
    public dialogRef: MatDialogRef<PassholderModalComponent>,
    private zpxApiService: ZpxApiService,
    private dataDogService: DataDogService,
    private appService: AppService,
    private selectedCompanyService: SelectedCompanyService
  ) {}

  data = inject<PassholderForTable>(MAT_DIALOG_DATA);

  passHolderEventSearchDate = '';
  patchBody: PassholderPatchBody;

  getPatchBody(body: PassholderPatchBody) {
    this.patchBody = body;
  }

  patchPassholder() {
    this.zpxApiService
      .patchPassholder(this.data.zpx_id, this.patchBody)
      .subscribe(
        (response) => {
          const patchedPassholder: Passholder = response;

          if (this.patchBody.passholder_type_id) {
            patchedPassholder.passholder_type_id =
              this.patchBody.passholder_type_id;
          }
          this.appService.patchedPassholder$.next(patchedPassholder);
          this.dialogRef.close();
        },
        (error) => {
          this.dataDogService.addRumError(error);
          console.error(error);
        }
      );
  }
  filterEvents() {
    //TODO Take the user to the filtered events page
  }

  hasEditPermission$ = this.selectedCompanyService
    .getUserPermissions()
    .pipe(
      map((permissionValues: string[]) =>
        permissionValues.includes('zpass:edit:passholder')
      )
    );
}
