import { PassholderEventsReportHttpResponseBody } from '@models/zpx-api.model';

export const mockEvents: PassholderEventsReportHttpResponseBody = {
  total_count: 3,
  count: 10,
  offset: 0,
  data: [
    {
      first_name: 'Gage',
      last_name: 'Black',
      card_number: '12345',
      ch_type: 'Driver',
      unique_id: '1001',
      odometer: '500',
      datetime: '2024-12-25T12:00:00Z',
      event_type: 'Standard Event'
    },
    {
      first_name: 'Gage',
      last_name: 'Black',
      card_number: '12345',
      ch_type: 'student',
      unique_id: '1001',
      odometer: '800',
      datetime: '2024-12-25T15:00:00Z',
      event_type: 'Standard Event'
    },
    {
      first_name: 'Gage',
      last_name: 'Black',
      card_number: '12345',
      ch_type: 'Aide',
      unique_id: '1001',
      odometer: '900',
      datetime: '2025-01-25T17:23:00Z',
      event_type: 'Standard Event'
    }
  ]
};

export class mockPassholderEvents {}
