<zui-table
  [title]="title"
  [columns]="columns"
  [dataSource]="dataSource"
  [enableMobileTable]="false"
  [initialPageSize]="initialPageSize"
  [enableColumnPicker]="false"
  (rowClick)="click($event)">
  <ng-content></ng-content>
</zui-table>
