import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import {
  ZonarUITableDataSource,
  ZonarUITableModel,
  ZonarUITableCellType
} from '@zonar-ui/table';
import { Params } from '@angular/router';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import {
  PassholderEventForTable,
  PASSHOLDER_EVENT_COLUMN_HEADERS,
  PassholderEvent,
  ZpxApiPassholderEventParams,
  PassholderEventsReportHttpResponseBody
} from '@src/app/models/zpx-api.model';
import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import * as dayjs from 'dayjs';
import { AppService } from '@src/app/app.service';
import { GetDivisionsService } from '@src/app/services/get-divisions/get-divisions.service';
import { mockEvents } from './mocks/mock-events';
@Injectable()
export class PassholderEventsTableDataSource implements ZonarUITableDataSource {
  constructor(
    private zpxApiService: ZpxApiService,
    private getEnvService: GetEnvironmentService
  ) {}

  private tableLoading = new BehaviorSubject<boolean>(false);
  private errorMessage = new BehaviorSubject<string>('');
  private totalResults = new BehaviorSubject<number>(0);
  private tableDataSubject$ = new BehaviorSubject<any[]>([]);
  private paginationParamsSubject = new BehaviorSubject<any>({});
  paginationParams$ = this.paginationParamsSubject.asObservable();
  loading$ = this.tableLoading.asObservable();
  total$ = this.totalResults.asObservable();
  errorMsg$ = this.errorMessage.asObservable();
  mockEvents = mockEvents;
  data: any[] = [];
  defaultPagination = true;

  private standardColumns: ZonarUITableModel[] = [
    {
      columnDef: 'last_name',
      header: PASSHOLDER_EVENT_COLUMN_HEADERS.LAST_NAME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderEventForTable) => p.last_name
    },
    {
      columnDef: 'first_name',
      header: PASSHOLDER_EVENT_COLUMN_HEADERS.FIRST_NAME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderEventForTable) => p.first_name
    },
    {
      columnDef: 'card_number',
      header: PASSHOLDER_EVENT_COLUMN_HEADERS.CARD_NUMBER,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderEventForTable) => p.card_number
    },

    {
      columnDef: 'ch_type',
      header: PASSHOLDER_EVENT_COLUMN_HEADERS.CH_TYPE,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderEventForTable) => p.ch_type
    },
    {
      columnDef: 'unique_id',
      header: PASSHOLDER_EVENT_COLUMN_HEADERS.UNIQUE_ID,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderEventForTable) => p.unique_id
    },
    {
      columnDef: 'odometer',
      header: PASSHOLDER_EVENT_COLUMN_HEADERS.ODOMETER,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderEventForTable) => p.odometer
    },
    {
      columnDef: 'date',
      header: PASSHOLDER_EVENT_COLUMN_HEADERS.DATE,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderEventForTable) => {
        const dateString = this.setDateString(p.datetime);
        return dateString;
      }
    },
    {
      columnDef: 'time',
      header: PASSHOLDER_EVENT_COLUMN_HEADERS.TIME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderEventForTable) => {
        const timeString = this.setTimeString(p.datetime);
        return timeString;
      }
    },
    {
      columnDef: 'event_type',
      header: PASSHOLDER_EVENT_COLUMN_HEADERS.EVENT_TYPE,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderEventForTable) => p.event_type
    }
  ];

  pageSize =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  loadData(params?: Params): void {
    this.paginationParamsSubject.next(params);
  }

  getStandardColumns(): ZonarUITableModel[] {
    return this.standardColumns;
  }

  setDateString(date: Date): string {
    const dateString = dayjs(date.toDateString()).format('YYYY/MM/DD');

    return dateString;
  }

  setTimeString(date: Date): string {
    const dateString = dayjs(date).format('HH:MM:ss');
    return dateString;
  }

  getPassholderEventData(passholderEvents: PassholderEvent[]): any[] {
    const eventsForTable = [];
    passholderEvents.forEach((events) => {
      let eventForTable = {
        last_name: events.last_name,
        first_name: events.first_name,
        card_number: events.card_number,
        ch_type: events.ch_type,
        unique_id: events.unique_id,
        odometer: events.odometer,
        datetime: new Date(events.datetime),
        event_type: events.event_type
      };
      eventsForTable.push(eventForTable);
    });
    return eventsForTable;
  }

  getCustomColumnsForTable() {
    return [];
  }
  getPassholderEventsForTable() {
    this.tableLoading.next(true);

    this.tableLoading.next(false);

    //replace this with getPassholderEventData when endpoint is live
    let mockEvents = this.mockEvents;
    this.data = this.getPassholderEventData(mockEvents.data);
    this.tableDataSubject$.next(this.data);
    this.totalResults.next(mockEvents.total_count);
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.tableDataSubject$.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableLoading.complete();
    this.tableDataSubject$.complete();
  }

  onTableDestroy(): void {
    this.tableDataSubject$.complete();
    this.tableLoading.complete();
  }
}
